import Button from '../components/Button'
import './scss/complete.scss'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { rootReducer } from '../store/store'
type AuthState = ReturnType<typeof rootReducer>
const Complete: React.FC = () => {
  const history = useHistory()
  const resInfo = useSelector((state: AuthState) => state.store)
  const data = resInfo.resInfo && resInfo.resInfo.data
  const close = () => {
    const newWindow = window.open('', '_self')
    newWindow?.close()
    history.push(`/main/${data?.companyId}/${data?.reservationId}`)
  }
  return (
    <>
      <div className="complete-root">
        <div className="content-wrap">
          <div>
            <img src="/img/complete.svg"></img>
          </div>
          <div>
            <h2>
              운전자 사전등록이
              <br />
              정상 완료되었습니다!
            </h2>
            <span>
              작성된 계약서는 출발 당일
              <br /> 차량 인수증 수령 후 문자 전송됩니다.
            </span>
          </div>
          <div className="complete-btn-wrap">
            <Button notWrap={true} onClick={close} children={'확인'}></Button>
          </div>
          *문제 발생시 렌터카 데스크로 문의바랍니다.
        </div>
      </div>
    </>
  )
}
export default Complete
