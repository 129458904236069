// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-wrap {
  padding: 20px;
  position: sticky;
  bottom: 0;
  border-top: 2px solid #f6f7f9;
  background: white;
  z-index: 9999;
}

.button {
  padding: 10px 20px;
  background-color: #4667d9;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
}

.button.disabled {
  cursor: none;
  background-color: #aeaeae;
}`, "",{"version":3,"sources":["webpack://./src/components/scss/Button.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,SAAA;EACA,6BAAA;EACA,iBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,yBAAA;AACF","sourcesContent":[".btn-wrap {\n  padding: 20px;\n  position: sticky;\n  bottom: 0;\n  border-top: 2px solid #f6f7f9;\n  background: white;\n  z-index: 9999;\n}\n\n.button {\n  padding: 10px 20px;\n  background-color: #4667d9;\n  color: #ffffff;\n  border: none;\n  border-radius: 8px;\n  cursor: pointer;\n  width: 100%;\n  font-size: 18px;\n  font-weight: 700;\n}\n\n.button.disabled {\n  cursor: none;\n  background-color: #aeaeae;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
