import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store, persistor } from './store/store'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Main from './pages/main'
import ResInfo from './pages/resInfo'
import AddProduct from './pages/addProduct'
import DriverInfo from './pages/driverInfo'
import PrivateRoute from './lib/PrivateRoute.js'
import Header from './pages/common/header'
import TimeoutRedirect from './lib/TimeoutRedirect.js'
import './App.css'
import Contract from './pages/contract'
import ResFinal from './pages/resFinal'
import Complete from './pages/complete'
import React from 'react'
import ScrollToTop from './lib/scrollToTop'
import Loading from './components/Lodding'
interface PrivateRouteProps {
  component: React.ComponentType<any>
  path: string
}

// const PrivateRoute: React.FC<PrivateRouteProps> = ({
//   component: Component,
//   ...rest
// }) => {
//   const isAuthenticated = useSelector((state: any) => {
//     console.log(state.auth.isAuthenticated);
//     return state.auth.isAuthenticated;
//   });

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
//       }
//     />
//   );
// };

const queryClient = new QueryClient()

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Header></Header>
          <div className="App">
            <div className="root-wrap">
              <BrowserRouter>
                <ScrollToTop />
                <Loading />
                <Switch>
                  {/* <TimeoutRedirect
                  timeout={300000}
                  redirectUrl="/main"
                ></TimeoutRedirect> */}
                  {/* <Route path="/" element={<Main />} /> */}
                  <Route
                    path="/main/:companyId/:reservationId"
                    component={Main}
                  />
                  {/* <Route path="resInfo" element={<ResInfo />} /> */}
                  <PrivateRoute component={ResInfo} exact path={'/resInfo'} />
                  <PrivateRoute
                    component={AddProduct}
                    exact
                    path={'/product'}
                  />
                  <PrivateRoute
                    component={DriverInfo}
                    exact
                    path={'/driverInfo'}
                  />
                  <PrivateRoute
                    component={Contract}
                    exact
                    path={'/contract'}
                  ></PrivateRoute>
                  <PrivateRoute
                    component={ResFinal}
                    exact
                    path={'/resFinal'}
                  ></PrivateRoute>
                  <PrivateRoute
                    component={Complete}
                    exact
                    path={'/complete'}
                  ></PrivateRoute>
                  {/* <Route component={Demo} exact path={'/demo'}></Route> */}
                </Switch>
              </BrowserRouter>
              {/* <Button>다음</Button> */}
            </div>
          </div>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
