import { useState } from 'react'

export const useCommonUtil = () => {

  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, '') // 숫자 이외의 문자 제거
    const match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/) // 숫자를 그룹으로 나누기
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}` // 하이픈으로 구분하여 반환
    }
    return phoneNumber // 변환할 수 없는 경우 원래 값 반환
  }

  function getNextDayIfPast(inputDate: Date): Date {
    const currentDate = new Date() // 현재 날짜 가져오기

    // 입력된 날짜가 현재 날짜보다 과거인 경우
    if (inputDate < currentDate) {
      const nextDay = new Date(currentDate)
      nextDay.setDate(nextDay.getDate() + 1) // 현재 날짜에 하루를 더함

      // "yyyyMMdd" 형식으로 포맷팅
      const year = nextDay.getFullYear()
      const month = (nextDay.getMonth() + 1).toString().padStart(2, '0')
      const day = nextDay.getDate().toString().padStart(2, '0')

      return new Date(year, Number(month), Number(day))
    }

    // 과거가 아닌 경우에는 입력된 날짜를 "yyyyMMdd" 형식으로 포맷팅하여 반환
    const year = inputDate.getFullYear()
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0')
    const day = inputDate.getDate().toString().padStart(2, '0')

    return new Date(year, Number(month), Number(day))
  }

  const formatDate = (birthday: string): string => {
    const year = birthday.slice(0, 2)
    const month = birthday.slice(2, 4)
    const day = birthday.slice(4, 6)

    const currentYear = new Date().getFullYear()
    const fullYear =
      parseInt(year) + (parseInt(year) >= currentYear % 100 ? 1900 : 2000) // 1900년과 2000년도 구분

    return `${fullYear}-${month}-${day}`
  }

  // 날짜를 "yyyy-MM-dd HH:mm" 형식으로 변환하는 함수
  function formatDate2(dateString: string): string {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  // 현재 시간과 지정된 날짜를 비교하는 함수
  function compareWithCurrentTime(targetDateString: string) {
    const targetDate = new Date(targetDateString)
    const currentDate = new Date()

    if (targetDate < currentDate) {
      return false
    } else if (targetDate > currentDate) {
      return true
    } else {
      return null
    }
  }

  const calculateDrivingExperience = (
    drivingLicenseDate: string,
  ): number | null => {
    // 현재 날짜를 구합니다.
    const currentDate = new Date()

    // 운전면허 발급일에서 년도, 월, 일을 추출합니다.
    const year = Number(drivingLicenseDate.slice(0, 4))
    const month = Number(drivingLicenseDate.slice(4, 6)) - 1 // 월은 0부터 시작하므로 1을 빼줍니다.
    const day = Number(drivingLicenseDate.slice(6, 8))

    // 운전면허 발급일을 Date 객체로 변환합니다.
    const licenseDate = new Date(year, month, day)

    // 운전 경력을 계산합니다.
    const experienceMilliseconds = currentDate.getTime() - licenseDate.getTime()
    const experienceYears =
      experienceMilliseconds / (1000 * 60 * 60 * 24 * 365.25) // 365.25일을 1년으로 간주하여 계산합니다.

    // 소수점 첫째 자리까지 반올림하여 운전 경력을 반환합니다.
    return Math.round(experienceYears * 10) / 10
  }

  const calculateAge = (birthdate: string): number | null => {
    // 현재 날짜를 구합니다.
    const currentDate = new Date()

    // 생년월일에서 년도, 월, 일을 추출합니다.
    const year = Number(birthdate.slice(0, 2))
    const month = Number(birthdate.slice(2, 4)) - 1 // 월은 0부터 시작하므로 1을 빼줍니다.
    const day = Number(birthdate.slice(4, 6))

    // 생년월일을 Date 객체로 변환합니다. 여기서 1900년대 출생자와 2000년대 출생자를 구분합니다.
    const birthDate = new Date(
      year < 24 ? 2000 + year : 1900 + year,
      month,
      day,
    )

    // 현재 날짜와 생년월일 사이의 차이를 계산합니다.
    const ageMilliseconds = currentDate.getTime() - birthDate.getTime()
    const ageYears = ageMilliseconds / (1000 * 60 * 60 * 24 * 365.25) // 365.25일을 1년으로 간주하여 계산합니다.

    // 소수점 첫째 자리까지 반올림하여 나이를 반환합니다.
    return Math.floor(ageYears)
  }
  const isValidDate = (dateString: string) => {
    // 날짜 형식을 검증하기 위한 정규 표현식
    const datePattern = /^\d{4}-\d{2}-\d{2}$/

    // 정규 표현식과 문자열을 비교하여 검증
    if (!datePattern.test(dateString)) {
      return false
    }

    // 유효한 날짜인지 확인
    const [year, month, day] = dateString.split('-').map(Number)

    if (month < 1 || month > 12) {
      return false // 올바른 월 범위 확인
    }

    const maxDay = new Date(year, month, 0).getDate() // 해당 월의 최대 일 수 계산
    if (day < 1 || day > maxDay) {
      return false // 올바른 일 범위 확인
    }

    return true // 모든 검증 통과
  }

  const isValidDate2 = (dateString: string) => {
    // 날짜 형식을 검증하기 위한 정규 표현식 (YYYYMMDD)
    const datePattern = /^\d{8}$/

    // 정규 표현식과 문자열을 비교하여 검증
    if (!datePattern.test(dateString)) {
      return false
    }

    // 유효한 날짜인지 확인
    const year = parseInt(dateString.substr(0, 4), 10)
    const month = parseInt(dateString.substr(4, 2), 10)
    const day = parseInt(dateString.substr(6, 2), 10)

    if (month < 1 || month > 12) {
      return false // 올바른 월 범위 확인
    }

    const maxDay = new Date(year, month, 0).getDate() // 해당 월의 최대 일 수 계산
    if (day < 1 || day > maxDay) {
      return false // 올바른 일 범위 확인
    }

    return true // 모든 검증 통과
  }

  const formatLicenseType = (type: string): string => {
    switch (type) {
      case '01003':
        return '11'
      case '01001':
        return '12'
      case '01002':
        return '13'
      case '02001':
        return '32'
      case '02002':
        return '33'
      case '02003':
        return '38'
      case '01004':
        return '14'
      case '01005':
        return '15'
      case '01006':
        return '16'
      default:
        return '' // 어떤 경우에도 매칭되지 않으면 빈 문자열 반환
    }
  }

  const sliceLicenseNumber = (licenseNumber: string): string => {
    const index = licenseNumber.split("-")

    return index[1]
  }

  const calculateYearsSinceIssued = (issueYearLastTwoDigits: number): number => {
    const currentYearLastTwoDigits = parseInt(String(new Date().getFullYear()).slice(-2));
    const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100; // 현재 세기

    const issueCentury = issueYearLastTwoDigits < currentYearLastTwoDigits ? currentCentury : currentCentury - 100; // 발급 연도의 세기
    const issueYear = issueCentury + issueYearLastTwoDigits; // 발급 연도 완성
    const currentYear = currentCentury + currentYearLastTwoDigits; // 현재 연도 완성

    return currentYear - issueYear;
  };

  const addHyphenToLicenseNumber = (licenseNumber: string): string => {
    if (!licenseNumber) return licenseNumber;

    // 앞 두글자가 숫자가 아니면
    if (!/^\d{2}/.test(licenseNumber)) {
      // 이미 하이픈이 포함된 형태인지 확인
      const isAlreadyFormatted = /^[가-힣]{0,4}-?\d{2}-\d{6}-\d{2}$/.test(licenseNumber);
      if (isAlreadyFormatted) return licenseNumber; // 이미 형식에 맞게 하이픈이 있는 경우 그대로 반환

      // 하이픈을 추가하여 반환
      return licenseNumber.replace(/([가-힣]{0,4})?(\d{2})(\d{6})(\d{2})/, '$1-$2-$3-$4');
    } else {
      // 이미 하이픈이 포함된 형태인지 확인
      const isAlreadyFormatted = /^[0-9]{2}-[0-9]{2}-[0-9]{6}-[0-9]{2}$/.test(licenseNumber);
      if (isAlreadyFormatted) return licenseNumber; // 이미 형식에 맞게 하이픈이 있는 경우 그대로 반환

      // 하이픈을 추가하여 반환
      return licenseNumber.replace(/(\d{2})(\d{2})(\d{6})(\d{2})/, '$1-$2-$3-$4');
    }
  }

  const formatBirthTime = (time: number): string => {
    const date = new Date(time);
    const year = date.getFullYear().toString().slice(-2); // 연도의 끝 두 자리
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월
    const day = date.getDate().toString().padStart(2, '0'); // 일

    return `${year}${month}${day}`;
  };

  return {
    formatPhoneNumber,
    formatLicenseType,
    formatDate,
    formatDate2,
    compareWithCurrentTime,
    calculateDrivingExperience,
    calculateAge,
    isValidDate,
    isValidDate2,
    getNextDayIfPast,
    sliceLicenseNumber,
    calculateYearsSinceIssued,
    addHyphenToLicenseNumber,
    formatBirthTime
  }
}
