import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { rootReducer } from '../store/store'
import Axios from '../api/axiosInstance';
import { CarInsuranceInfo } from '../types/CarInsuranceChargeTypes';
import TitleHeader from '../components/TitleHeader'
import { AxiosResponse } from "axios";

type AuthState = ReturnType<typeof rootReducer>

interface AddInsuranceProps {
    selectCarInsurance: (event: React.ChangeEvent<HTMLInputElement>, item: CarInsuranceInfo) => void,
    noSelectCarInsurance: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AddInsurance: React.FC<AddInsuranceProps> = (
    {selectCarInsurance, noSelectCarInsurance}
) => {
    const resInfo = useSelector((state: AuthState) => state.store.resInfo);
    const [insArray, setInsArray] = useState<Array<CarInsuranceInfo>>([]);
    const [selectedInsuranceId, setSelectedInsuranceId] = useState(0);

    const getInsuranceList = async () => {

        const req = {
            companyId: resInfo?.data?.companyId,
            customerId: resInfo?.data?.customerId,
            modelId: resInfo?.data?.modelId,
            takeoverDtm: resInfo?.data?.takeOverStrDtm,
            returnDtm: resInfo?.data?.returnStrDtm
        }

        await Axios().post('/V2/kiosk/selectCarInsurance.do', req).then((res: AxiosResponse<CarInsuranceInfo>) => {
            setInsArray(res.data.list.filter(ins => ins.carInsuranceInfo.insuranceType !== '4000'));
            console.log(res.data.list);
        })
    }

    const formatNumber = (value: number): string => {
        return value.toLocaleString('ko-KR');
    }

    const addLineBreaks = (text: string) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
            {line}
            <br />
            </React.Fragment>
        ));
    };

    const makeInsuranceContent = (insurance: CarInsuranceInfo): string => {
        const immunity = insurance.chargeImmunity;
        const limit = insurance.limitPrice;
        
        return "면책금 " + immunity + "만원 / 한도 " + limit + "만원 ";
    }

    const onChangeInsurance = (
        event: React.ChangeEvent<HTMLInputElement>, item: CarInsuranceInfo
    ) => {
        selectCarInsurance(event, item);
        setSelectedInsuranceId(item.insuranceId);
    }

    const onChangeNoInsurance = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedInsuranceId(0);
        noSelectCarInsurance(event);
    }

    useEffect(() => {
        getInsuranceList();
    }, []);

    return (
        <>
            <TitleHeader title={'자차보험 상품'}></TitleHeader>
            <div className="ins-wrap">
            {insArray && insArray.length > 0 ? (
                <>
                <div>
                    <label className={'ins-charge-wrap'}>
                        <div className="ins-title-wrap">
                            <div className="nm-radio">
                                <input type="radio"
                                        value={0}
                                        checked={0 === selectedInsuranceId}
                                        onChange={onChangeNoInsurance}
                                />
                                <span className="radio"></span>
                            </div>
                            <div className="label-wrap">
                              <span className="label-text">선택 안함</span>
                            </div>
                        </div>
                    </label>
                </div>
                {insArray.map((item: CarInsuranceInfo, index: number) => (
                <div key={index}>
                    <label className='ins-charge-wrap'>
                        <div className="ins-title-wrap">
                            <div key={item.insuranceId} className="nm-radio">
                                <input
                                    type="radio"
                                    value={item.insuranceId}
                                    checked={item.insuranceId === selectedInsuranceId}
                                    onChange={(event) => {
                                        onChangeInsurance(event, item)
                                    }}
                                />
                                <span className="radio"></span>
                            </div>

                            <div className="label-wrap">
                                <span className="label-text">
                                    {' '}
                                    {item.insuranceName}
                                </span>
                                <span className="label-price">
                                    {formatNumber(Number(item.chargeValue))}원
                                </span>
                            </div>
                        </div>
                        <br />

                        <div className="ins-content">
                            <span>
                                {addLineBreaks(makeInsuranceContent(item.carInsuranceInfo) || '내용 없음')}
                            </span>
                            <span>
                                {addLineBreaks(("단독사고 " + item.carInsuranceInfo.soloAccidentInclusionString) || '')}
                            </span>
                        </div>
                    </label>
                </div>
                ))}
                </>
            ) : (
                <div className="ins-charge-wrap">등록된 자차보험 상품이 없습니다.</div>
            )}
            </div>
        </>
    );
}

export default AddInsurance;