export const login = () => {
  return {
    type: "LOGIN" as const,
  };
};

export const logout = () => {
  return {
    type: "LOGOUT" as const,
  };
};

export const resInfo = (data: any) => {
  return {
    type: "RESERVATION",
    payload: data.data,
  } as const;
};

export const product = (data: any) => {
  return {
    type: "PRODUCT",
    payload: data,
  } as const;
};

export const driver = (data: any) => {
  return {
    type: "DRIVER",
    payload: data,
  } as const;
};

export const file = (array: any) => {
  return {
    type: "FILE",
    payload: array,
  } as const;
};

export const singFile = (array: any) => {
  return {
    type: "SIGN_FILE",
    payload: array,
  } as const;
};
