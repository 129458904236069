import { Reservation } from '../types/ReservationTypes'

export interface AuthState {
  isAuthenticated: boolean
  resInfo: ResInfo | null
  product: Product | null
  driver: Driver | null
  file: FileList | null
  signFile: Array<any> | null
  loading: boolean
}

export interface ResInfo {
  data: Reservation
}
export interface Product {
  data: any
}

export interface Driver {
  data: any
}

export type AuthAction =
  | { type: 'LOGIN' }
  | { type: 'LOGOUT' }
  | { type: 'PRODUCT'; payload: any }
  | { type: 'DRIVER'; payload: any }
  | { type: 'RESERVATION'; payload: any }
  | { type: 'FILE'; payload: FileList | null }
  | { type: 'SIGN_FILE'; payload: Array<any> | null }
  | { type: 'LOADING' }
  | { type: 'LOADED' }
const initialState: AuthState = {
  isAuthenticated: false,
  resInfo: null,
  product: null,
  driver: null,
  file: null,
  signFile: [],
  loading: false,
}

const authReducer = (
  state: AuthState = initialState,
  action: AuthAction,
): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
      }
    case 'RESERVATION':
      return {
        ...state,
        resInfo: {
          data: action.payload,
        },
      }
    case 'PRODUCT':
      return {
        ...state,
        product: {
          data: action.payload,
        },
      }
    case 'DRIVER':
      return {
        ...state,
        driver: {
          data: action.payload,
        },
      }
    case 'FILE':
      return {
        ...state,
        file: action.payload,
      }
    case 'SIGN_FILE':
      return {
        ...state,
        signFile: action.payload,
      }
    case 'LOADING': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'LOADED': {
      return {
        ...state,
        loading: false,
      }
    }
    default:
      return state
  }
}

export default authReducer
