import "./scss/TitleHeader.scss";

interface Porps {
  title: string;
  children?: any;
}

const TitleHeader: React.FC<Porps> = ({ title, children }) => {
  return (
    <div className="title-header-wrap">
      <div className="header-children">
        {title} {children}
      </div>
      {/* <div>{children}</div> */}
    </div>
  );
};

export default TitleHeader;
