import React from "react";
import "./header.scss";

const Header: React.FC = () => {
  return (
    <div className="header-wrap">
      <div className="header-content">운전자 사전등록</div>
    </div>
  );
};

export default Header;
