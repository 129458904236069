// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-wrap {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.wrapper {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin-bottom: 100px;
}

.wrapper.title-etc {
  font-size: 18px;
  font-weight: 400;
}

.wrapper .box-wrap {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.wrapper .box-wrap .box {
  background: white;
  width: 80%;
  min-height: 150px;
  max-width: 300px;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.content {
  font-size: 16px;
}

.input-wrapper {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/scss/main.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAGA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EAEA,oBAAA;AADF;;AAMA;EACE,eAAA;EACA,gBAAA;AAHF;;AAMA;EACE,UAAA;EACA,cAAA;EACA,mBAAA;AAHF;;AAKA;EAEE,iBAAA;EACA,UAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAHF;;AAKA;EACE,eAAA;EACA,mBAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,mBAAA;EACA,kBAAA;EACA,mBAAA;AAFF","sourcesContent":[".main-wrap {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 80vh;\n  // height: calc(100vh - 100px);\n}\n\n.wrapper {\n  width: 100%;\n  max-width: 800px;\n  padding: 20px;\n  //   background-color: #f2f2f2;\n  margin-bottom: 100px;\n  //   border-radius: 10px;\n  //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n\n.wrapper.title-etc {\n  font-size: 18px;\n  font-weight: 400;\n}\n\n.wrapper .box-wrap {\n  width: 80%;\n  margin: 0 auto;\n  margin-bottom: 20px;\n}\n.wrapper .box-wrap .box {\n  //   background: #F1F4FF;\n  background: white;\n  width: 80%;\n  min-height: 150px;\n  max-width: 300px;\n  margin: 0 auto;\n}\n.title {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n\n.content {\n  font-size: 16px;\n}\n\n.input-wrapper {\n  margin-bottom: 10px;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
