// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complete-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.content-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progress-wrap {
  height: 6vh;
}

.complete-btn-wrap {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.complete-btn-wrap > div {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/scss/complete.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".complete-root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  padding: 20px;\n}\n\n.content-wrap {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.progress-wrap {\n  height: 6vh;\n}\n\n.complete-btn-wrap {\n  height: 20vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.complete-btn-wrap > div {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
