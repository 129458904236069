// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 15px !important;
  padding: 16px 16px 0 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  width: 35vh !important;
}

.modal-content {
  /* 추가적인 모달 내용 스타일 정의 */
}

.modal-close {
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.modal-sign {
  background: #e9ebed;
  width: 100%;
  height: 20vh;
  border-radius: 4px;
}

.modal-header > div > button {
  background-color: white;
  border: 1px solid #cacdd2;
  border-radius: 3px;
}

.canvasStyle {
  width: 100%;
  height: 100%;
}

.modal-btn-wrap {
  padding: 20px;
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/components/scss/CommonModal.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;AAEF;;AACA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,uBAAA;EACA,8BAAA;EACA,yBAAA;EACA,wCAAA;EACA,cAAA;EACA,sBAAA;AAEF;;AACA;EACE,sBAAA;AAEF;;AACA;EACE,QAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAEF;;AACA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAEF;;AACA;EACE,uBAAA;EACA,yBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,WAAA;EACA,YAAA;AAGF;;AAAA;EACE,aAAA;EACA,gBAAA;EACA,SAAA;EACA,iBAAA;EACA,aAAA;AAGF","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 9999;\n}\n\n.modal-container {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 15px !important;\n  padding: 16px 16px 0 16px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  z-index: 10000;\n  width: 35vh !important;\n}\n\n.modal-content {\n  /* 추가적인 모달 내용 스타일 정의 */\n}\n\n.modal-close {\n  top: 8px;\n  right: 8px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  white-space: nowrap;\n}\n\n.modal-header {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.modal-sign {\n  background: #e9ebed;\n  width: 100%;\n  height: 20vh;\n  border-radius: 4px;\n}\n\n.modal-header > div > button {\n  background-color: white;\n  border: 1px solid #cacdd2;\n  border-radius: 3px;\n}\n.canvasStyle {\n  width: 100%;\n  height: 100%;\n}\n\n.modal-btn-wrap {\n  padding: 20px;\n  position: sticky;\n  bottom: 0;\n  background: white;\n  z-index: 9999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
