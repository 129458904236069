import React from "react";
import "./scss/Button.scss";

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children?: string;
  disabled?: boolean;
  notWrap?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  children,
  disabled,
  notWrap,
}) => {
  return (
    <div className={notWrap ? "" : "btn-wrap"}>
      <button
        disabled={disabled}
        className={disabled ? "button disabled" : "button"}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
