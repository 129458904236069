import Button from "../components/Button";
import Check from "../components/Check";
import TitleHeader from "../components/TitleHeader";
import "./scss/basePrice.scss";
import { useParams, Link, useHistory } from "react-router-dom";
import React, { ChangeEventHandler, useState } from "react";
interface Props {
  minAge?: Number;
  minCareer?: Number;
}

const BasicPre: React.FC<Props> = ({ minAge, minCareer }) => {
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = () => {
    history.push("/driverInfo");
  };

  return (
    <>
      <div className="pre-wrap">
        <div className="title-wrap">
          <TitleHeader title={"기본유의사항"}></TitleHeader>
        </div>
        <div className="pre-content-wrap">
          <div className="pre-content">
            1. 이 차량은 만 {String(minAge)}세, 운전경력 {String(minCareer)}년 이상 운전자만 사용 가능합니다.
            <br />
            <br /> 2. 예약 차량의 렌트업체명, 모델명, 출발/반납 시간, 보험명, 총
            금결제 금액을 확인하였습니다.
            <br /> <br /> 3. 위조 및 타인의 운전면허증(신분증 및 면허정보) 또는
            신용카드를사용하여 발생하는 모든 민/형사 상의 책임은 본인에게
            있습니다.
            <br />
            <br /> 4. 손해면책제도를 포함해도 운전 경력(면허취득)이 기준보다
            낮을 경우 자차 보상처리가 되지 않습니다.
          </div>
        </div>

        <div className="agree-wrap">
          <Check
            label={
              "차량 손해 면책 내용과 유의 사항을 모두 읽고 확인하였습니다."
            }
            onChange={handleChange}
            active={isChecked}
          ></Check>
        </div>
        <div className="temp-area"></div>
      </div>
      <Button
        onClick={handleClick}
        disabled={!isChecked}
        children={"다음"}
      ></Button>
    </>
  );
};

export default BasicPre;
