import React, { ReactNode } from "react";
import Button from "./Button";

import "./scss/CommonModal.scss";

interface CommonModalProps {
  children?: ReactNode;
  onClose: () => void;
  title?: string;
  content?: string;
}

const CommonModal: React.FC<CommonModalProps> = ({
  children,
  onClose,
  title,
  content,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          {/* <button className="modal-close" onClick={onClose}>
            Close
          </button> */}
        </div>
        <div className="modal-content">
          <h2>{title}</h2>
          <p>{content}</p>
        </div>
        <div className="modal-btn-wrap">
          <Button
            onClick={() => {
              onClose();
            }}
            notWrap={true}
          >
            확인
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommonModal;
