// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wrap {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #f6f7f9;
  background: white;
  z-index: 9999;
}

.header-content {
  font-size: 20px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/common/header.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,iBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF","sourcesContent":[".header-wrap {\n  position: sticky;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-bottom: 2px solid #f6f7f9;\n  background: white;\n  z-index: 9999;\n}\n\n.header-content {\n  font-size: 20px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
