import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from "react";
import "./scss/Input.scss";

interface InputProps {
  type: string;
  value: string | '';
  defaultValue?: string;
  accept?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string; // className prop 추가
  readOnly?: boolean;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
}

const Input: React.FC<InputProps> = ({
  type,
  value,
  defaultValue,
  onChange,
  className,
  readOnly,
  placeholder,
  accept,
  minLength,
  maxLength,
}) => {
  return (
    <input
      type={type ? type : "text"}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      pattern={type === "number" ? "\\d*" : ""}
      className={className ? className : "input-type1"}
      readOnly={readOnly}
      placeholder={placeholder ? placeholder : ""}
      maxLength={maxLength}
    />
  );
};

export default Input;
