// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 화면 높이를 가득 채우도록 설정 */
  background-color: rgba(255, 255, 255, 0.8); /* 배경을 반투명하게 설정 */
  position: fixed; /* 화면 고정 위치로 설정 */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999; /* 다른 요소 위에 표시되도록 설정 */
}`, "",{"version":3,"sources":["webpack://./src/components/scss/Lodding.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA,EAAA,sBAAA;EACA,0CAAA,EAAA,iBAAA;EACA,eAAA,EAAA,iBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA,EAAA,sBAAA;AAEF","sourcesContent":[".loading {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* 화면 높이를 가득 채우도록 설정 */\n  background-color: rgba(255, 255, 255, 0.8); /* 배경을 반투명하게 설정 */\n  position: fixed; /* 화면 고정 위치로 설정 */\n  top: 0;\n  left: 0;\n  width: 100%;\n  z-index: 9999; /* 다른 요소 위에 표시되도록 설정 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
