// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.res-info-wrap {
  flex-direction: column;
  text-align: left;
  padding: 10px;
}
.res-info-wrap span {
  margin-bottom: 10px;
  white-space: nowrap;
}

.res-wrap {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.res-wrap > div {
  color: #9fa4a9;
  font-weight: 500;
  margin-bottom: 10px;
}

.res-wrap .span-container {
  display: flex;
}

.res-wrap .span-container .label {
  color: #9fa4a9;
  margin-right: 10px;
  min-width: 100px;
  text-align: left;
}

.res-wrap .span-container > span {
  color: #464c52;
}

.ins-wrap {
  padding: 10px;
}

.ins-wrap .ins-content {
  padding: 16px;
  background: #f6f7f9;
  border-radius: 8px;
  white-space: normal;
  color: #4667d9;
}

.ins-wrap .ins-content .ins-detail {
  margin-top: 10px;
  color: #FA5858;
  font-weight: 500;
  text-align: justify;
  line-height: 1.4em;
}

.ins-wrap .ins-content .label {
  color: #464c52;
  font-weight: 700;
}

.ins-wrap .ins-content .value {
  color: #4667d9;
  font-weight: 500;
  margin-left: 10px;
}

.ins-wrap .ins-name {
  color: #9fa4a9;
  font-weight: 500;
}

.line {
  background: #f6f7f9;
  height: 1.2vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/scss/resInfo.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,gBAAA;EACA,aAAA;AACF;AACE;EACE,mBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,aAAA;AAAF","sourcesContent":[".res-info-wrap {\n  flex-direction: column;\n  text-align: left;\n  padding: 10px;\n\n  span {\n    margin-bottom: 10px;\n    white-space: nowrap;\n  }\n}\n\n.res-wrap {\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n.res-wrap > div {\n  color: #9fa4a9;\n  font-weight: 500;\n  margin-bottom: 10px;\n}\n\n.res-wrap .span-container {\n  display: flex;\n}\n\n.res-wrap .span-container .label {\n  color: #9fa4a9;\n  margin-right: 10px;\n  min-width: 100px;\n  text-align: left;\n}\n\n.res-wrap .span-container > span {\n  color: #464c52;\n}\n\n.ins-wrap {\n  padding: 10px;\n}\n\n.ins-wrap .ins-content {\n  padding: 16px;\n  background: #f6f7f9;\n  border-radius: 8px;\n  white-space: normal;\n  color: #4667d9;\n  // margin-top: 15px;\n}\n.ins-wrap .ins-content .ins-detail {\n  margin-top: 10px;\n  color: #FA5858;\n  font-weight: 500;\n  text-align: justify;\n  line-height: 1.4em;\n}\n\n.ins-wrap .ins-content .label {\n  color: #464c52;\n  font-weight: 700;\n}\n\n.ins-wrap .ins-content .value {\n  color: #4667d9;\n  font-weight: 500;\n  margin-left: 10px;\n}\n\n.ins-wrap .ins-name {\n  color: #9fa4a9;\n  font-weight: 500;\n}\n\n.line {\n  background: #f6f7f9;\n  height: 1.2vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
