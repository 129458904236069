// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-header-wrap {
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid #e9ebed;
  line-height: 2.5;
  padding-left: 10px;
}

.header-children {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/scss/TitleHeader.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,gCAAA;EACA,gBAAA;EAEA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF","sourcesContent":[".title-header-wrap {\n  font-weight: 600;\n  font-size: 18px;\n  border-bottom: 1px solid #e9ebed;\n  line-height: 2.5;\n  //   margin-bottom: 15px;\n  padding-left: 10px;\n}\n\n.header-children {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
