// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pre-wrap {
  text-align: left;
  padding: 10px;
}

.pre-wrap .pre-content-wrap {
  padding: 10px;
}

.pre-wrap .pre-content-wrap .pre-content {
  padding: 16px;
  background: #f6f7f9;
  border-radius: 8px;
  margin-top: 15px;
  text-align: justify;
  line-height: 1.4em;
}

.pre-wrap .agree-wrap {
  padding: 10px;
}

.pre-wrap .next-wrap {
  text-align: center;
  margin-top: 30px;
}

.temp-area {
  height: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/scss/basePrice.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;AACF;;AAIA;EACE,aAAA;AADF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,kBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,WAAA;AAAF","sourcesContent":[".pre-wrap {\n  text-align: left;\n  padding: 10px;\n}\n\n.pre-wrap .title-wrap {\n}\n.pre-wrap .pre-content-wrap {\n  padding: 10px;\n}\n.pre-wrap .pre-content-wrap .pre-content {\n  padding: 16px;\n  background: #f6f7f9;\n  border-radius: 8px;\n  margin-top: 15px;\n  text-align: justify;\n  line-height: 1.4em;\n}\n\n.pre-wrap .agree-wrap {\n  padding: 10px;\n}\n\n.pre-wrap .next-wrap {\n  text-align: center;\n  margin-top: 30px;\n}\n\n.temp-area {\n  height: 3vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
