// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nm-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.nm-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: #e9ebed;
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.switch:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 19px;
  left: 3px;
  bottom: 2px;
  background-color: #c8c8c8;
  transition: 0.2s;
  border-radius: 20px;
}

.nm-switch input:checked + .switch {
  background-color: #4667d9;
}

.nm-switch input:disabled + .switch {
  background: #f1f1f1;
  border: 1px solid #c8c8c8 !important;
  cursor: not-allowed;
}

.nm-switch input:checked + .switch:before {
  transform: translateX(19px);
  background: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/scss/Swich.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACF;;AACA;EACE,UAAA;EACA,QAAA;EACA,SAAA;AAEF;;AAAA;EACE,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,4EAAA;AAGF;;AAGA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;AAAF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,mBAAA;EACA,oCAAA;EACA,mBAAA;AACF;;AACA;EACE,2BAAA;EACA,mBAAA;AAEF","sourcesContent":[".nm-switch {\n  position: relative;\n  display: inline-block;\n  width: 45px;\n  height: 25px;\n}\n.nm-switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n.switch {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  border-radius: 20px;\n  background-color: #e9ebed;\n  transition: 0.2s;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;\n  //   border: 1px solid #c8c8c8;\n}\n.nm-switch:hover .switch {\n  //   border: 1px solid #4667d9;\n}\n.switch:before {\n  position: absolute;\n  content: \"\";\n  height: 21px;\n  width: 19px;\n  left: 3px;\n  bottom: 2px;\n  background-color: #c8c8c8;\n  transition: 0.2s;\n  border-radius: 20px;\n}\n.nm-switch input:checked + .switch {\n  background-color: #4667d9;\n  //   border: 1px solid #4667d9;\n}\n.nm-switch input:disabled + .switch {\n  background: #f1f1f1;\n  border: 1px solid #c8c8c8 !important;\n  cursor: not-allowed;\n}\n.nm-switch input:checked + .switch:before {\n  transform: translateX(19px);\n  background: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
