import React from "react";
import { useSelector } from "react-redux";
import "./scss/resInfo.scss";
import TitleHeader from "../components/TitleHeader";
import Step from "../components/Step";
import BasicPre from "../pages/basicPre";
import { rootReducer } from "../store/store";
import { AdditionChargeInfo } from "../types/AdditionChargeInfoTypes";
import { CarInsuranceChargeInfo } from "../types/CarInsuranceChargeTypes";
import { AdditionRestChargeInfo } from "../types/AdditionRestChargeTypes";

type AuthState = ReturnType<typeof rootReducer>;

const ResInfo: React.FC = () => {
  const resInfo = useSelector((state: AuthState) => state.store.resInfo);
  const data = resInfo && resInfo.data;
  console.log(data?.additionRestChargeInfoArray)

  const formatNumber = (value: number): string => {
    const formattedValue = value.toLocaleString('ko-KR')
    return formattedValue
  }
  return (
    <>
      <div className="res-info-wrap">
        <Step phase="1"></Step>
        <TitleHeader title={"예약확인"}></TitleHeader>
        <div className="res-wrap">
          <div className="span-container">
            <span className="label">예약번호</span>
            <span className="value">{data?.reservationId}</span>
          </div>

          <div className="span-container">
            <span className="label"> 예약일시</span>
            <span className="value">{data?.reservationDtm}</span>
          </div>

          <div className="span-container">
            <span className="label">예약자 명</span>
            <span className="value">{data?.req1stUserName}</span>
          </div>

          <div className="span-container">
            <span className="label">연락처</span>
            <span className="value">{data?.req1stUserTel}</span>
          </div>

          <div className="span-container">
            <span className="label"> 렌트 업체명</span>
            <span className="value">{data?.carOwnerCompanyName ? data.carOwnerCompanyName : data?.companyName}</span>
          </div>

          <div className="span-container">
            <span className="label"> 대여 차종</span>
            <span className="value"> {data?.modelName}</span>
          </div>

          <div className="span-container">
            <span className="label">출발 일시</span>
            <span className="value">{data?.takeOverStrDtm}</span>
          </div>

          <div className="span-container">
            <span className="label">반납 일시</span>
            <span className="value">{data?.returnStrDtm}</span>
          </div>

          <div className="span-container">
            <span className="label"> 총 대여 시간</span>
            <span className="value"> {data?.totalUseHour}</span>
          </div>

          {data?.feeFieldInput != null && Number(data?.feeFieldInput) > 0 && (
            <div className="span-container">
              <span className="label" style={{ fontWeight: '700' }}> 대여료 미납금</span>
              <span className="value" style={{ color: '#FA5858', fontWeight: '700' }}> {formatNumber(data?.feeFieldInput - (data?.accountInRequester || 0 - data?.accountOutRequester || 0))}원</span>
            </div>
          )}

          <div className="span-container">
            <span className="label">기타 상품 </span>{" "}
            {/* 기타 상품 목록 출력 */}
            {data?.additionRestChargeInfoArray &&
              data?.additionRestChargeInfoArray.map(
                (item: AdditionRestChargeInfo, index: number) => (
                  <React.Fragment key={index}>
                    <div style={{ color: '#464c52' }}>
                      {index > 0 && <span>&nbsp;/&nbsp;</span>}
                      <span>{item.restChargeInfo.productName}</span>
                    </div>
                  </React.Fragment>
                )
              )}
          </div>
        </div>

        <div className="ins-wrap">
          <span className="ins-name">차량손해면책</span><br /><br />

          {data?.carInsuranceChargeInfoArray && data?.carInsuranceChargeInfoArray?.length > 0 ? (
            data.carInsuranceChargeInfoArray.map(
              (ins: CarInsuranceChargeInfo, index: number) => (
                <div className="ins-content" key={index}>
                  <div>
                    <span className="label">가입 상품</span>
                    <span className="value">{ins.carInsuranceInfo.insuranceName}</span>
                    <br /> <br />
                  </div>
                  <div>
                    <span className="label">상품 내용</span>
                    <div className="ins-detail">
                      {ins.carInsuranceInfo.insuranceTypeString} / 만{" "}
                      {ins.carInsuranceInfo.minAge}세 이상 / 운전경력{" "}
                      {ins.carInsuranceInfo.minCareer}년 이상 / 도로교통법상 유효한 면허로
                      차종 및 인원에 따라 1종, 2종 면허 소지자
                      {/* 보상한도 {ins.carInsuranceInfo.limitPrice}만원 / 면책금{" "}
              {ins.carInsuranceInfo.chargeImmunity}만원(수리비용 한도 초과시 초과
              수리비 임차인 부담) / 단독사고{" "}
              {ins.carInsuranceInfo.soloAccidentInclusionString
                ? ins.carInsuranceInfo.soloAccidentInclusionString
                : "미포함"}{" "}
              / 기타 :{" "}
              {ins.carInsuranceInfo.content
                ? ins.carInsuranceInfo.content
                : "없음"} */}
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              )
            )
          ) : (
            <div className="ins-content">
              <div>
                <span className="label">상품 내용</span>
                <div className="ins-detail">
                  해당 없음
                </div>
              </div>
              <br />
              <br />
            </div>
          )}
        </div>

      </div>

      <div className="line"></div>
      <BasicPre
        minAge={data?.rentCondAge}
        minCareer={data?.rentCondLicenseYear}
      ></BasicPre>
    </>
  );
};

export default ResInfo;
