import React, { ChangeEventHandler, useState } from 'react'
import { useSelector } from 'react-redux'
import './scss/resFinal.scss'
import Step from '../components/Step'
import TitleHeader from '../components/TitleHeader'
import Button from '../components/Button'
import axiosInstance from '../api/axiosInstance'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CommonModal from '../components/CommonModal'
import { rootReducer } from '../store/store'
import { CarInsuranceInfo } from '../types/CarInsuranceChargeTypes'
import { AdditionChargeInfo } from '../types/AdditionChargeInfoTypes'
import { Driver } from '../types/DriverTypes'
import { AdditionRestChargeInfo } from '../types/AdditionRestChargeTypes'
type AuthState = ReturnType<typeof rootReducer>
const ResFinal: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [alertTitle, setAlertTitle] = useState<string>('')
  const [alertContent, setAlertContent] = useState<string>('')
  const [finalSubmitBtn, setFinalSubmitBtn] = useState<boolean>(false)
  const resInfo = useSelector((state: AuthState) => state.store)
  const data = resInfo.resInfo && resInfo.resInfo.data
  console.log(resInfo);

  const driver = resInfo.driver && resInfo.driver.data
  console.log('driver', driver)

  const add = resInfo.product && resInfo.product.data
  const history = useHistory()
  let totalPrice = 0
  const addTotal = () => {
    let name = ''
    console.log(add.addProductArr)
    add &&
      add.addProductArr.map(
        (
          e: {
            productName: string
            chargeRequester: number
          },
          index: number,
        ) => {
          if (index !== add.addProductArr.length) name += e.productName + '<br />'
          else name += e.productName
          totalPrice += e.chargeRequester
        },
      )


    add &&
      add.addInsuranceArr.map((e: CarInsuranceInfo, index: number) => {
        if (index !== add.addInsuranceArr.length) name += e.insuranceAddName + '<br />'
        else name += e.insuranceAddName
        totalPrice += e.chargeRequester
      })

    // data?.additionRestChargeInfoArray &&
    // data?.additionRestChargeInfoArray.map(
    //     (e: AdditionRestChargeInfo, index: number) => {
    //       if (index !== 0) name += ' / '
    //       if (e.restChargeInfo.productName) name += e.restChargeInfo.productName
    //       totalPrice += e.chargeRequester
    //     },
    //   )

    return name
  }

  // if (data?.feeFieldInput && data?.feeFieldInput > 0) {
  //   totalPrice += data?.feeFieldInput - ((data?.accountInRequester || 0) -
  //     (data?.accountOutRequester || 0))
  // }

  const finalSubmit = async () => {
    console.log('driver', driver)
    setFinalSubmitBtn(true);
    const headers = {
      data: 'data',
      encType: 'multipart/form-data',
      processData: false,
    }
    const formData = new FormData()

    if (resInfo.file && resInfo.file.length > 0) {
      for (let i = 0; i < resInfo.file.length; i++) {
        formData?.append('file', resInfo.file[i])
      }
    }

    formData?.append('companyId', data?.companyId || '');

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/Mobile/V1/ReservationLicensFileUpload.do`,
        formData,
        {
          headers,
        },
      )
      .then(async (res) => {

        // if (res.data?.files.length !== 0) {
        // for (let i = 0; i < res.data?.files.length; i++) {
        //   driver[i].licenseFileGroupId = res.data?.files[i].fileGroupId
        // }
        // }

        for (let i = 0; i < driver.length; i++) {
          if (driver[i].changeLicense) {
            console.log(driver[i])
            console.log(driver[i].licenseFileGroupId)
            for (let x = 0; x < res.data?.files.length; x++) {
              if (res.data?.files[i] && res.data?.files[i].fileGroupId) {
                driver[i].licenseFileGroupId = res.data?.files[i].fileGroupId
              } else {
                driver[i].licenseFileGroupId = res.data?.files[x].fileGroupId
              }

            }
            // driver[i].licenseFileGroupId = res.data?.files[i]?.fileGroupId || ''
          }
        }

        console.log("driver  ====", driver)
        if (res.data?.result === 'Success') {
          const req = {
            companyId: data?.companyId,
            reservationId: data?.reservationId,
            modelId: data?.modelId,
            modelName: data?.modelName,
            addProductArr: add.addProductArr,
            addInsuranceArr: add.addInsuranceArr,
            driverList: driver,
          }
          await axiosInstance()
            .post('/Mobile/V1/ReservationSubmit.do', req)
            .then(async (res) => {
              if (res.data?.result) {
                setFinalSubmitBtn(false)
                history.push('/complete')
              } else {
                setAlertTitle('완료 된 건입니다.')
                setAlertContent(res.data?.msg)
                setShowModal(true)
                setFinalSubmitBtn(false)
                return false
              }
            })
        }
      })
      .catch((e) => {
        console.error(e)
        setAlertTitle('오류 발생')
        setAlertContent('최종 제출 중 문제가 발생했습니다.')
        setShowModal(true)
        setFinalSubmitBtn(false)
        return false
      })
    setFinalSubmitBtn(false)
  }

  const formatNumber = (value: number): string => {
    const formattedValue = value.toLocaleString('ko-KR')
    return formattedValue
  }
  return (
    <>
      <div className="res-info-wrap">
        <Step phase={'5'}></Step>
        <TitleHeader title={'예약 최종확인'}></TitleHeader>
        <div className="res-wrap">
          <div className="span-container">
            <span className="label">예약번호</span>
            <span className="value">{data?.reservationId}</span>
          </div>
          <div className="span-container">
            <span className="label"> 예약일시</span>
            <span className="value">{data?.reservationDtm}</span>
          </div>

          <div className="span-container">
            <span className="label">예약자 명</span>
            <span className="value">{data?.req1stUserName}</span>
          </div>

          <div className="span-container">
            <span className="label">연락처</span>
            <span className="value">{data?.req1stUserTel}</span>
          </div>

          <div className="span-container">
            <span className="label"> 렌트 업체명</span>
            <span className="value">{data?.carOwnerCompanyName ? data?.carOwnerCompanyName : data?.companyName}</span>
          </div>

          <div className="span-container">
            <span className="label"> 대여 차종</span>
            <span className="value"> {data?.modelName}</span>
          </div>

          <div className="span-container">
            <span className="label">출발 일시</span>
            <span className="value">{data?.takeOverStrDtm}</span>
          </div>

          <div className="span-container">
            <span className="label">반납 일시</span>
            <span className="value">{data?.returnStrDtm}</span>
          </div>

          <div className="span-container">
            <span className="label"> 총 대여 시간</span>
            <span className="value"> {data?.totalUseHour}시간</span>
          </div>

          {data?.feeFieldInput !== null && Number(data?.feeFieldInput) > 0 && (
            <div className="span-container">
              <span className="label" style={{ fontWeight: '700' }}> 대여료 미납금</span>
              <span className="value" style={{ color: '#FA5858', fontWeight: '700' }}> {formatNumber(Number((data?.feeFieldInput || 0) - ((data?.accountInRequester || 0) - (data?.accountOutRequester || 0))))}
                원</span>
            </div>
          )}

          <div className="span-container">
            <span className="label">운전자명 </span> {/* 기타 상품 목록 출력 */}
            {driver &&
              driver.map((item: Driver, index: number) => (
                <React.Fragment key={index}>
                  {index > 0 && <span>,&nbsp;</span>}
                  <span>{item.driverName}</span>
                </React.Fragment>
              ))}
          </div>

          <div className="span-container">
            <span className="label"> 추가옵션</span>
            <span className="value" dangerouslySetInnerHTML={{ __html: addTotal() }}></span>
          </div>
        </div>
      </div>

      <div className="line"> </div>
      <div className="pay-total-wrap">
        <div className="pay-total-child">
          <span className="pay-total-title">결제예정 금액</span>
          <span className="pay-total">
            {formatNumber(
              totalPrice + (
                Number(
                  (data?.feeFieldInput || 0) -
                  ((data?.accountInRequester || 0) -
                    (data?.accountOutRequester || 0))
                )
              )
            )}원
          </span>
        </div>
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <div className="pay-ment">
            *추가 금액은 현장 키오스크에서 결제 진행됩니다.
          </div>
        </div>
      </div>
      <Button
        onClick={finalSubmit}
        disabled={finalSubmitBtn}
        children={'제출하기'}
      ></Button>
      {showModal && (
        <CommonModal
          onClose={() => {
            setShowModal(false)
          }}
          title={alertTitle}
          content={alertContent}
        ></CommonModal>
      )}
    </>
  )
}

export default ResFinal
