export type Driver = {
  companyId: number;
  reservationId: string;
  orderNum: number;
  driverName: string;
  tel: string;
  birthday: string;
  licenseType: string;
  licenseNum: string;
  licenseDate: boolean;
  licenseCheckDtm: string;
  singImg?: string;
  licenseFileGroupId?: string
  changeLicense?: boolean;
  typeDateBirthday?: number;
  emContactNumber?: string
};

export const defaultDriver: Driver[] = [
  {
    companyId: 0,
    reservationId: "",
    orderNum: 0,
    driverName: "",
    tel: "",
    birthday: "",
    licenseType: "01001",
    licenseNum: "",
    licenseDate: false,
    licenseCheckDtm: "",
    licenseFileGroupId: '',
    changeLicense: false,
    typeDateBirthday: 0,
    emContactNumber: ""
  },
  {
    companyId: 0,
    reservationId: "",
    orderNum: 1,
    driverName: "",
    tel: "",
    birthday: "",
    licenseType: "01001",
    licenseNum: "",
    licenseDate: false,
    licenseCheckDtm: "",
    licenseFileGroupId: '',
    changeLicense: false,
    typeDateBirthday: 0
  },
];
