// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-label {
  font-size: 16px;
  line-height: 2rem;
}

.input-type1 {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-type1:focus {
  outline: 1px solid #4667d9;
}

input::placeholder {
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/scss/Input.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EAEE,eAAA;AAAF","sourcesContent":[".input-label {\n  font-size: 16px;\n  line-height: 2rem;\n}\n\n.input-type1 {\n  width: 100%;\n  padding: 10px;\n  font-size: 14px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  box-sizing: border-box;\n}\n\n.input-type1:focus {\n  outline: 1px solid #4667d9;\n}\n\ninput::placeholder {\n  //   text-align: center;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
