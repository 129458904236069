import Step from '../components/Step'
import TitleHeader from '../components/TitleHeader'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import './scss/contract.scss'
import axiosInstance from '../api/axiosInstance'
import Check from '../components/Check'
import SignatureModal from '../components/SignatureModal'
import Modal from '../components/SignatureModal'
import Button from '../components/Button'
import { rootReducer } from '../store/store'
import { Driver } from '../types/DriverTypes'
import { TermsType } from '../types/TermsTypes'
import DriverInfo from './driverInfo'
type AuthState = ReturnType<typeof rootReducer>


type AgreementState = {
  privacyAgreement: boolean; // 개인정보 수집/이용 동의 여부
  thirdPartyAgreement: boolean; // 개인정보 3자 제공 동의 여부
  allAgreementsChecked: boolean; // 모든 동의 항목 체크 여부
  vehicleLeaseContractConditions: boolean; // 차량 임대 계약조건 동의 여부
};

interface SginState {
  driver1Sgin: boolean
  driver2Sgin: boolean
}



const Contract: React.FC = () => {
  const [termList, setTermList] = useState<[]>()
  const [rentInfo, setRentInfo] = useState<string>('')
  const [privateInfo, setPrivateInfo] = useState<string>('')
  const [rentCheckInfo, setRentCheckInfo] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const [driverNum, setDriverNum] = useState<number>(0)
  const [agreement, setAgreement] = useState<AgreementState>({
    privacyAgreement: false,
    thirdPartyAgreement: false,
    allAgreementsChecked: false,
    vehicleLeaseContractConditions: false,
  });
  const [driverSginState, setDriverSginState] = useState<SginState>({ driver1Sgin: false, driver2Sgin: false })

  const resInfo = useSelector((state: AuthState) => state.store)
  const data = resInfo.resInfo && resInfo.resInfo.data
  const [driverListProp, setDriverListProp] = useState<Driver[]>([])
  const driverList = resInfo.driver && resInfo.driver.data

  const history = useHistory()

  const TermList = async () => {
    await axiosInstance()
      .post('/Mobile/V1/CompanyTermList.do', {
        companyId: data?.companyId,
      })
      .then((res) => {
        setTermList(res.data.data)
        if (res.data && res.data.data.length > 0) {
          res.data.data.map((e: TermsType) => {
            if (e.termsType === 'RENT_INFO' && e.contents)
              setRentInfo(e.contents)
            else if (e.termsType === 'PRIVATE_INFO' && e.contents)
              setPrivateInfo(e.contents)
            else if (e.termsType === 'RENT_CHECK_INFO' && e.contents)
              setRentCheckInfo(e.contents)
          })
        }
      })
  }

  const handleAllCheck = () => {
    const allAgree = !agreement.allAgreementsChecked

    setAgreement({
      vehicleLeaseContractConditions: allAgree,
      privacyAgreement: allAgree,
      thirdPartyAgreement: allAgree,
      allAgreementsChecked: allAgree
    })
  }
  const handleCheck = (type: keyof AgreementState) => {
    setAgreement(prev => ({
      ...prev,
      [type]: !prev[type],
      allAgreementsChecked: areAllTrue({ ...prev, [type]: !prev[type] })
    }));
  };

  const btnCheck = () => {
    if (driverList && driverList.length === 1) {
      return !(agreement.allAgreementsChecked && driverSginState.driver1Sgin);
    } else if (driverList && driverList.length > 1) {
      return !(agreement.allAgreementsChecked && driverSginState.driver1Sgin && driverSginState.driver2Sgin);
    } else {
      return false;
    }
  };

  const nextStep = () => {
    driverList.forEach((item: any) => {
      if (item.singImg) item.singImg = ''
    });
    history.push('/resFinal')
  }

  const areAllTrue = (obj: any) => {
    const values = Object.entries(obj)
      .filter(([key]) => key !== 'allAgreementsChecked')
      .map(([_, value]) => value);
    return values.every(value => value === true);
  };

  const agreementStyles = {
    agree: { color: '#4667d9', fontWeight: '700' },
    disagree: { color: '#FA5858' }
  };

  useEffect(() => {
    TermList()
  }, [])

  return (
    <>
      <div className="contract-root">
        <Step phase={'4'}></Step>
        <TitleHeader title={'전자 계약서'}></TitleHeader>
        <div className="content-wrap">
          <div className="content">
            <div className="content-title">
              <span>(필수) </span>
              <span className="label">자동차 대여 표준 약관</span>
            </div>
            <div
              className="rent-info-car-terms"
              dangerouslySetInnerHTML={{ __html: rentCheckInfo }}
            ></div>
          </div>

        </div>
        <div className="content-wrap">
          <div className="content">
            <div className="content-title">
              <span className="etc">(필수) </span>
              <span className="label">차량 임대 계약조건</span>
            </div>
            <div
              className="rent-info"
              dangerouslySetInnerHTML={{ __html: rentInfo }}
            ></div>
          </div>
          <div className="rent-info-check">
            <Check
              label={'위 계약조건을 모두 확인하고 숙지하였습니다.'}
              value={agreement.vehicleLeaseContractConditions}
              style={agreement.vehicleLeaseContractConditions ? agreementStyles.agree : agreementStyles.disagree}
              onChange={() => handleCheck('vehicleLeaseContractConditions')}
            ></Check>
          </div>
        </div>
        <div className="content-wrap">
          <div className="content">
            <div className="content-title">
              <span>(필수) </span>
              <span className="label">
                개인정보수집, 이용 및 개인정보의 제 3자 제공 동의서
              </span>
            </div>
            <div
              className="rent-info"
              dangerouslySetInnerHTML={{ __html: privateInfo }}
            ></div>
          </div>
          <div className="rent-info-check">
            <div className="private-ment">
              위와 같이 개인정보를 수집/이용하는데 동의하십니까?
            </div>

            <div className="private-check-wrap">
              <Check
                label={'동의'}
                value={agreement.privacyAgreement}
                style={agreement.privacyAgreement ? agreementStyles.agree : agreementStyles.disagree}
                onChange={() => handleCheck('privacyAgreement')}
              ></Check>
              &nbsp; &nbsp;
              {/* <Check
                label={"미동의"}
                value={privacyAgreement}
                onChange={() => handleCheck("PRIVATE_INFO_NO")}
              ></Check> */}
            </div>
            <div className="content-ment">
              *위 개인정보 수집/이용 동의를 거부할 권리가 있습니다. 단, 대여를
              위한 최소한의 필수 개인정보 수집/이용을 거부할 경우, 계약 체결이
              불가능합니다.{' '}
            </div>

            <div className="private-ment">
              위와 같이 개인정보를 제 3자에게 제공하는데 동의하십니까?
            </div>
            <div className="private-check-wrap">
              <Check
                label={'동의'}
                value={agreement.thirdPartyAgreement}
                style={agreement.thirdPartyAgreement ? agreementStyles.agree : agreementStyles.disagree}
                onChange={() => handleCheck('thirdPartyAgreement')}
              ></Check>
              &nbsp; &nbsp;
              {/* <Check
                label={"미동의"}
                value={!thirdPartyAgreement}
                onChange={() => handleCheck("PRIVATE_INFO2")}
              ></Check> */}
            </div>
            <div className="content-ment">
              *위 개인정보 3자 제공 동의를 거부할 권리가 있습니다. 단, 동의를
              거부 할시 이용 상의 불이익이 발생될 수 있습니다. (ex. 계약 거절)
            </div>
          </div>
        </div>

        <div className="rent-info-check">
          <Check
            label={'위 약관을 모두 확인하고 숙지하였습니다.'}
            value={agreement.allAgreementsChecked}
            active={agreement.allAgreementsChecked}
            style={agreement.allAgreementsChecked ? agreementStyles.agree : agreementStyles.disagree}
            onChange={() => handleAllCheck()}
          ></Check>
        </div>
      </div>

      <div className="line"></div>

      <div className="sign-root">
        <div className="sign-ment">
          본인(임차인, 운전자)은 계약서상에 기재된 제반 사항 및 약관의 내용을
          숙지하고 승인하였기에 아래와 같이 서명합니다.
        </div>
        {driverList &&
          driverList.map((item: Driver, index: number) => (
            <div className="sign-wrap">
              <div className="sign-info">
                <div style={{ width: '30%' }}>
                  {index === 0 ? '주 운전자' : '부 운전자'}
                </div>
                <div style={{ width: '30%' }}>{item.driverName}</div>
                <div
                  className="sign"
                  onClick={() => {
                    setIsOpen(true)
                    setDriverNum(index + 1)
                    setDriverListProp((prevState) => [...prevState, item])
                  }}
                >
                  {item.singImg ? null : '(서명)'}
                  {item.singImg && (
                    <img
                      src={item.singImg}
                      style={{ width: '50%', height: '100%' }}
                      alt="Signature"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}

        <Modal
          isOpen={isOpen}
          setDriverSginState={setDriverSginState}
          driverNum={driverNum}
          driverListProp={driverListProp}
          onClose={() => {
            setIsOpen(false)
          }}
        ></Modal>
      </div>

      <Button
        onClick={nextStep}
        disabled={btnCheck()}
        children={'다음'}
      ></Button>
    </>
  )
}

export default Contract
