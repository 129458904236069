import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux' // useDispatch를 함수형 컴포넌트 내에서 import
import axios from 'axios'
import { store, persistor } from '../store/store'
import { login } from '../actions/authActions'
const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:8010/Service`

const Axios = () => {
  // 요청 인터셉터 추가 (선택 사항)

  const axiosInstance = axios.create({
    baseURL: `${apiUrl}`, // 원하는 기본 URL을 설정하세요.
    headers: {
      'Content-Type': 'application/json', // 원하는 헤더 설정 (선택 사항)
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
  })

  axiosInstance.interceptors.request.use(
    (config) => {
      store.dispatch({
        type: 'LOADING',
      })

      // 요청을 보내기 전에 수행할 작업을 여기에 추가할 수 있습니다.
      return config
    },
    (error) => {
      store.dispatch({
        type: 'LOADED',
      })
      // 요청이 실패한 경우 처리할 작업을 여기에 추가할 수 있습니다.
      return Promise.reject(error)
    },
  )

  // 응답 인터셉터 추가 (선택 사항)
  axiosInstance.interceptors.response.use(
    (response) => {
      // 응답을 받기 전에 수행할 작업을 여기에 추가할 수 있습니다.
      store.dispatch({
        type: 'LOADED',
      })
      return response
    },
    (error) => {
      store.dispatch({
        type: 'LOADED',
      })
      // 응답이 실패한 경우 처리할 작업을 여기에 추가할 수 있습니다.
      return Promise.reject(error)
    },
  )

  return axiosInstance
}

export default Axios
