import React, { ChangeEventHandler, useEffect, useState } from 'react'
import Input from '../components/Input'
import Button from '../components/Button'
import './scss/main.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import axiosInstance from '../api/axiosInstance'
import { useMutation } from 'react-query'
import { login, resInfo } from '../actions/authActions'
import CommonModal from '../components/CommonModal'
import Axios from '../api/axiosInstance'
import authReducer from '../reducers/authReducer'
import axios from 'axios'
import { createStore } from 'redux'
const Main: React.FC = () => {
  const [userTel, setUserTel] = useState<string>('')
  const history = useHistory()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [alertTitle, setAlertTitle] = useState<string>('검색 결과가 없습니다.')
  const [alertContent, setAlertContent] = useState<string>('')


  const store = createStore(authReducer);
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value.length > event.target.maxLength)
      event.target.value = event.target.value.slice(0, event.target.maxLength)
    setUserTel(event.target.value)
  }

  const isAuthenticated = useSelector(
    (state: any) => state.store.isAuthenticated,
  )

  const { companyId, reservationId } = useParams<{
    companyId: string
    reservationId: string
  }>()
  sessionStorage.clear()
  const mutation = useMutation(
    'reservation',
    async () =>
      await Axios()
        .post('/Mobile/V1/ReservationInfo.do', {
          companyId: companyId,
          reservationId: reservationId,
          req1stUserTel: formatPhoneNumber(userTel),
        })
        .then((res) => res.data),
    {},
  )

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    store.replaceReducer(authReducer);
    if (!userTel) {
      setAlertContent('전화번호를 입력해주세요.')
      setShowModal(true)
      return false
    }

    const data = await mutation.mutateAsync()


    if (data.data) {
      const currentDate = new Date();
      const givenDate = new Date(data.data.takeoverDtm);

      if (givenDate < currentDate) {
        setAlertContent('인수 시간이 지난 건입니다.')
        setShowModal(true)
        return false
      }
    }

    // if (data.data && data.data.preRegStatus === 'Y') {
    //   setAlertContent('이미 접수된 건 입니다.')
    //   setShowModal(true)
    //   return false
    // }

    //02000 출고 스테이터스

    if (data.msg !== 'Fail' && data && data.data.procressStatus === '99999') {
      setAlertContent('취소된 예약 건 입니다.')
      setShowModal(true)
      return false
    }
    if (data && data.msg !== 'Fail') {
      dispatch(login())
      dispatch(resInfo(data))
      sessionStorage.setItem('auth', isAuthenticated)
      isAuthenticated && history.push('/resInfo')
    } else {
      setAlertContent('예약 정보를 다시 확인해주세요.')
      setShowModal(true)
      return false
    }
  }

  const formatPhoneNumber = (phoneNumber: string) => {
    // 전화번호에서 하이픈(-)을 제외한 숫자만 추출
    const digits = phoneNumber.replace(/-/g, '')

    // 추출한 숫자를 원하는 포맷으로 변환
    const formattedPhoneNumber = digits.replace(
      /(\d{3})(\d{3,4})(\d{4})/,
      '$1-$2-$3',
    )

    return formattedPhoneNumber
  }

  useEffect(() => {
    if (mutation.isSuccess && sessionStorage.getItem('auth')) {
      history.push('/resInfo')
      sessionStorage.setItem('auth', isAuthenticated)
    }
  }, [mutation.isSuccess, isAuthenticated, history])
  return (
    <div className="main-wrap">
      <div className="wrapper">
        <h1 className="title">
          운전자 사전 등록으로 <br />
          차량 인수를 간편하게!
        </h1>
        <p className="title-etc">예약자 연락처로 조회해보세요.</p>
        {/* <div className="input-wrapper">
          <label className="input-label">예약번호</label>
          <Input
            type="text"
            className="input-type1"
            value={`${reservationId}`}
            readOnly={true}
          ></Input>
        </div> */}
        <div className="box-wrap">
          <img src="/img/Character.svg"></img>
        </div>
        <div>
          <div className="input-wrapper">
            <Input
              type={'number'}
              className="input-type1"
              onChange={handleChange}
              value={userTel}
              maxLength={11}
              placeholder={'‘-’없이 핸드폰 번호를 입력해주세요.'}
            ></Input>
          </div>
          <div className="input-wrapper">
            <Button
              onClick={handleClick}
              children={'예약조회'}
              notWrap={true}
            ></Button>
          </div>
        </div>
      </div>
      {showModal && (
        <CommonModal
          onClose={() => {
            setShowModal(false)
          }}
          title={alertTitle}
          content={alertContent}
        ></CommonModal>
      )}
    </div>
  )
}

export default Main
