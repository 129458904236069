import { CSSProperties, ChangeEventHandler } from 'react'
import './scss/Check.scss'

interface CheckProps {
  label?: string
  onChange?: ChangeEventHandler<HTMLInputElement> | any
  active?: boolean
  value?: any
  checked?: boolean
  style?: CSSProperties
}

const Check: React.FC<CheckProps> = ({ label, onChange, active, value, checked, style }) => {
  return (
    <>
      <label className={!active ? 'custom-checkbox' : 'custom-checkbox active'} style={style}>
        <input type="checkbox" onChange={onChange} value={value} checked={checked !== undefined ? checked : value} />
        <span className="checkmark"></span>
        {label}
      </label>
    </>
  )
}

export default Check
