// Loading.js
import React from 'react'
import './scss/Lodding.scss'
import { useDispatch, useSelector } from 'react-redux'
import { rootReducer } from '../store/store'
type AuthState = ReturnType<typeof rootReducer>
const Loading = () => {
  const loading = useSelector((state: AuthState) => state.store.loading)
  console.log(loading)
  if (!loading) {
    return null
  }
  return (
    <>
      <div className="loading">
        <img src="/img/lodding.svg" style={{ width: '20%' }}></img>
      </div>
    </>
  )
}

export default Loading
