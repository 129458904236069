import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axiosInstance from '../api/axiosInstance'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import TitleHeader from '../components/TitleHeader'
import './scss/addProduct.scss'
import Step from '../components/Step'
import Check from '../components/Check'
import Button from '../components/Button'
import { product } from '../actions/authActions'
import { rootReducer } from '../store/store'
import { CarInsuranceChargeInfo, CarInsuranceInfo } from '../types/CarInsuranceChargeTypes'
import { AdditionRestChargeInfo } from '../types/AdditionRestChargeTypes'
import { AdditionChargeInfo } from '../types/AdditionChargeInfoTypes'
interface RestChargeInfo {
  restChargeId: number
  productName: string
  chargeRequester: number
  chargeBase: number
  chargeValue: number
  reservationSubSerial: null | number
}

interface RestChargeInsInfo {
  insuranceId: number
  chargeBase: number
  chargeRequester: number;
  chargeValue: number;
  insuranceAddName: string
  insuranceName: string
  insuranceType: string
}
type AuthState = ReturnType<typeof rootReducer>

const AddProduct: React.FC = () => {
  const resInfo = useSelector((state: AuthState) => state.store.resInfo)
  const data = resInfo && resInfo.data

  const [checkedItems, setCheckedItems] = useState<RestChargeInfo[]>([]) // 기타상품
  const [checkedIns, setCheckedIns] = useState<RestChargeInsInfo[]>([]) // 특약보험
  const [isChecked, setIsChecked] = useState(false) // 확인 여부
  const [totalAddAmt, setTotalAddAmt] = useState<number>(0)
  const [totalInsAmt, setTotalInsAmt] = useState<number>(0)
  const [unSelected, setUnSelected] = useState<boolean>(false)
  const [orginAddition, setOrginAddition] = useState<any[]>(resInfo?.data.additionRestChargeInfoArray && [...resInfo?.data.additionRestChargeInfoArray] as any);

  const [insuranceId, setInsuracneId] = useState<number>(0)
  const history = useHistory()
  const dispatch = useDispatch()

  console.log(orginAddition);

  const handleAddCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: RestChargeInfo,
    chargeBase: number,
    chargeValue: number,
    chargeRequester: number,
  ) => {
    const restChargeId = item.restChargeId

    const filteredItem: RestChargeInfo = {
      restChargeId: restChargeId,
      chargeRequester: chargeRequester,
      chargeBase: chargeBase,
      chargeValue: chargeValue,
      productName: item.productName,
      reservationSubSerial: item.reservationSubSerial
    }

    console.log('chargeBase', item)
    if (event.target.checked) {
      // 체크박스가 선택된 경우
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, filteredItem])
      setTotalAddAmt((prevTotalAmt) => prevTotalAmt + chargeRequester)
    } else {
      // 체크박스가 선택 해제된 경우
      setCheckedItems((prevCheckedItems: any) =>
        prevCheckedItems.filter(
          (checkedItem: any) => checkedItem.restChargeId !== restChargeId,
        ),
      )
      setTotalAddAmt((prevTotalAmt) => prevTotalAmt - chargeRequester)
    }
  }

  /*
            추후에 특약이 여러개 들어가야 된다면 사용
        */
  //   const handleInsCheckboxChange = (
  //     event: React.ChangeEvent<HTMLInputElement>,
  //     item: RestChargeInsInfo,
  //     chargeBase: number
  //   ) => {
  //     const insId = item.insuranceId;

  //     const filteredItem: RestChargeInsInfo = {
  //       insuranceId: item.insuranceId,
  //       chargeBase: item.chargeBase,
  //       insuranceAddName: item.insuranceName,
  //       insuranceName: item.insuranceName,
  //     };
  //     if (event.target.checked) {
  //       // 체크박스가 선택된 경우
  //       setCheckedIns((prevCheckedItems) => [...prevCheckedItems, filteredItem]);
  //       setTotalAmt((prevTotalAmt) => prevTotalAmt + chargeBase);
  //     } else {
  //       // 체크박스가 선택 해제된 경우
  //       setCheckedIns((prevCheckedItems) =>
  //         prevCheckedItems.filter(
  //           (checkedItem: any) => checkedItem.insuranceId !== insId
  //         )
  //       );
  //       setTotalAmt((prevTotalAmt) => prevTotalAmt - chargeBase);
  //     }
  //   };

  const handleInsRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: RestChargeInsInfo,
    chargeBase: number,
  ) => {
    const filteredItem: RestChargeInsInfo = {
      insuranceId: item.insuranceId,
      chargeBase: item.chargeBase,
      chargeRequester: item.chargeRequester,
      chargeValue: item.chargeValue,
      insuranceAddName: item.insuranceName,
      insuranceName: item.insuranceName,
      insuranceType: item.insuranceType
    }

    if (event.target.checked) {
      // 라디오 버튼이 선택된 경우
      setInsuracneId(item.insuranceId)
      setCheckedIns([filteredItem])
      setTotalInsAmt(item.chargeRequester)
    }
  }

  const formatNumber = (value: number): string => {
    const formattedValue = value.toLocaleString('ko-KR')
    return formattedValue
  }

  const nextStep = async () => {
    // const data? = await mutation.mutateAsync();
    // if (data?.result) history.push("/driverInfo");
    const req = {
      companyId: data?.companyId,
      reservationId: data?.reservationId,
      modelId: data?.modelId,
      modelName: data?.modelName,
      addProductArr: checkedItems,
      addInsuranceArr: checkedIns,
    }
    console.log(checkedItems)
    dispatch(product(req))
    history.push('/driverInfo')
  }

  const mutation = useMutation(
    'addProduct',
    async () =>
      await axiosInstance()
        .post('/Mobile/V1/ReservationSubMobile.do', {
          companyId: data?.companyId,
          reservationId: data?.reservationId,
          modelId: data?.modelId,
          modelName: data?.modelName,
          addProductArr: checkedItems,
          addInsuranceArr: checkedIns,
        })
        .then((res) => res.data),
    {},
  )

  const isCheckedItem = (item: any) => {
    return checkedItems.some(
      (checkedItem) =>
        checkedItem.restChargeId === item.restChargeInfo.restChargeId,
    )
  }

  const handleChange = () => {
    setIsChecked(!isChecked)
  }
  const addLineBreaks = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };


  useEffect(() => {
    setTotalAddAmt(0)
    setCheckedItems([])
    setCheckedIns([])
    const newItems = data?.additionChargeInfoArray && data?.additionChargeInfoArray.filter((chargeInfo) => {
      return orginAddition && !orginAddition.some((restChargeInfo: AdditionRestChargeInfo) => restChargeInfo.restChargeId === chargeInfo.restChargeId);
    });

    orginAddition && orginAddition.push(...newItems as any);

    console.log('orginAddition', orginAddition)
    orginAddition && orginAddition.map((e: AdditionRestChargeInfo) => {
      if (e.reservationId) {
        setCheckedItems((prevCheckedItems) => [...prevCheckedItems,
        {
          restChargeId: e.restChargeId,
          chargeRequester: e.chargeRequester,
          productName: e.restChargeInfo.productName,
          chargeValue: e.chargeValue,
          chargeBase: e.chargeBase,
          reservationSubSerial: e.reservationSubSerial
        }
        ]
        )
        setTotalAddAmt((prevTotalAmt) => prevTotalAmt + e.chargeRequester)
      }
    })


    data?.carInsuranceChargeInfoArray && data?.carInsuranceChargeInfoArray.map(item => {
      if (item.carInsuranceInfo.insuranceType === '4000') {
        setInsuracneId(item.insuranceId)
        setCheckedIns([{
          insuranceId: item.insuranceId,
          chargeBase: item.chargeRequester,
          chargeRequester: item.chargeRequester,
          chargeValue: item.chargeValue,
          insuranceAddName: item.insuranceName,
          insuranceName: item.insuranceName,
          insuranceType: item.carInsuranceInfo.insuranceType
        }])
        setTotalInsAmt(item.chargeRequester)
      }
      data?.carInsuranceInfoArray && data?.carInsuranceInfoArray.map(ins => {
        if (ins.insuranceId === item.insuranceId) ins.chargeBase = item.chargeRequester
      })
    })
  }, []);
  return (
    <>
      <div className="add-wrap">
        <Step phase={'2'}></Step>
        <TitleHeader title={'자차특약 상품'}></TitleHeader>
        <div className="ins-wrap">
          {data?.carInsuranceChargeInfoArray && data.carInsuranceChargeInfoArray?.length > 0 ? (
            data.carInsuranceChargeInfoArray.map((ins: CarInsuranceChargeInfo, index: number) => (
              ins.carInsuranceInfo.insuranceType !== '4000' && (
                <div className="ins-content" key={index}>
                  <div>
                    <span className="label">가입 상품</span>
                    <span className="value">{ins.carInsuranceInfo.insuranceName}</span>
                    <br /> <br />
                  </div>
                  <div>
                    <span className="label">상품 내용</span>
                    <div className="ins-detail">
                      보상한도 {ins.carInsuranceInfo.limitPrice}만원 / 면책금{" "}
                      {ins.carInsuranceInfo.chargeImmunity}만원(수리비용 한도 초과시 초과
                      수리비 임차인 부담) / 단독사고{" "}
                      {addLineBreaks(ins.carInsuranceInfo.contentRole)}
                      {addLineBreaks(ins.carInsuranceInfo.contentBurden)}
                      {addLineBreaks(ins.carInsuranceInfo.content)}
                    </div>
                  </div>
                </div>
              )
            ))
          ) : (
            <div className="ins-content">
              <div>
                <span className="label">상품 내용</span>
                <div className="ins-detail">
                  해당 없음
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="ins-wrap">
          {data?.carInsuranceInfoArray &&
            data.carInsuranceInfoArray.length === 0 ? (
            <div className="ins-charge-wrap">등록된 특약상품이 없습니다.</div>
          ) : (
            data?.carInsuranceInfoArray && data.carInsuranceInfoArray.filter((e) => data?.carInsuranceChargeInfoArray && data.carInsuranceChargeInfoArray?.length > 0 && data.carInsuranceChargeInfoArray[0].carInsuranceInfo.insuranceType !== e.carInsuranceInfo.relatedInsuranceType)
              .map(
                (item: CarInsuranceInfo, index: number) => (
                  <div>
                    <label
                      className={
                        item.insuranceId === insuranceId
                          ? 'ins-charge-wrap active'
                          : 'ins-charge-wrap'
                      }
                    >

                      <div className="ins-title-wrap">

                        <div key={item.insuranceId} className="nm-radio">
                          <input
                            type="radio"
                            value={insuranceId}
                            checked={item.insuranceId === insuranceId}
                            onChange={(event) =>
                              handleInsRadioChange(event, item, item.chargeBase)
                            }
                          />
                          <span className="radio"></span>
                        </div>
                        <div className="label-wrap">
                          <span className="label-text">
                            {' '}
                            {item.insuranceName}
                          </span>
                          <span className="label-price">
                            {formatNumber(Number(item.chargeRequester))}원
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="ins-content">
                        {/* 책임한도 : {item.limitPrice}원 / 단독사고{' '}
                      {item.soloAccidentInclusionString
                        ? item.soloAccidentInclusionString
                        : '미포함'}{' '}
                      <br /> */}
                        {item.carInsuranceInfo.contentBurden && (
                          <span>
                            {item.carInsuranceInfo.contentBurden}
                          </span>
                        )}<br /><br />
                        {item.carInsuranceInfo.content && (
                          <span>
                            {addLineBreaks(item.carInsuranceInfo.content)}
                          </span>
                        )}<br />
                        {!item.carInsuranceInfo.contentBurden && !item.carInsuranceInfo.content && (
                          <>내용 없음</>
                        )}
                      </div>
                    </label>
                    {data?.carInsuranceInfoArray &&
                      index === data.carInsuranceInfoArray.filter((e) => data?.carInsuranceChargeInfoArray && data.carInsuranceChargeInfoArray[0].carInsuranceInfo.insuranceType !== e.carInsuranceInfo.relatedInsuranceType).length - 1 && (
                        <label
                          className={
                            0 === insuranceId
                              ? 'ins-charge-wrap active'
                              : 'ins-charge-wrap'
                          }
                        >
                          <div className="ins-title-wrap">
                            <div className="nm-radio">
                              <input
                                type="radio"
                                value={insuranceId}
                                checked={0 === insuranceId}
                                onChange={(event) => {
                                  setInsuracneId(0)
                                  setCheckedIns([])
                                  setTotalInsAmt(0)
                                }}
                              />
                              <span className="radio"></span>
                            </div>
                            <div className="label-wrap">
                              <span className="label-text">선택 안함</span>
                            </div>
                          </div>
                        </label>
                      )}
                  </div>
                ),
              )
          )}
        </div>
        {/* <div>
        기타 상품 :
        {orginAddition.map((item: any, index: number) => (
          <label key={item.restChargeInfo.restChargeId}>
            <input
              type="checkbox"
              value={item.restChargeInfo.restChargeId}
              //   checked={checkedItems.includes(
              //     Number(item.restChargeInfo.restChargeId)
              //   )}
              onChange={(event) =>
                handleAddCheckboxChange(
                  event,
                  item.restChargeInfo as RestChargeInfo,
                  item.chargeRequester
                )
              }
            />
            {item.restChargeInfo.productName}
          </label>
        ))}
      </div> */}
        {/* <br />총 결제 금액(현장결제) : {totalAmt}
      <div>
        <button onClick={nextStep}>다음</button>
      </div> */}
      </div>
      <div className="line"></div>

      {/* 기타상품 영역 */}
      <div className="add-product-wrap">
        <TitleHeader title={'기타 상품'}></TitleHeader>
        <div className="add-product">
          {orginAddition &&
            orginAddition.length === 0 ? (
            <div className="add-wrap">
              <div className="add-charge-wrap">
                등록된 기타 상품이 없습니다.
              </div>
            </div>
          ) : (
            orginAddition &&
            orginAddition.map(
              (item: AdditionRestChargeInfo, index: number) => (
                <label
                  className={`add-wrap${isCheckedItem(item) ? ' active' : ''}`}
                >
                  <label className="add-charge-wrap">
                    <div className="add-title-wrap">
                      <Check
                        value={item.restChargeId}
                        checked={isCheckedItem(item)}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                          handleAddCheckboxChange(
                            event,
                            item.restChargeInfo,
                            item.chargeBase,
                            item.chargeValue,
                            item.chargeRequester,
                          )
                        }
                      ></Check>
                      <div className="add-title">
                        <span>{item.restChargeInfo.productName}</span>
                        <span className="label-price">
                          {formatNumber(item.chargeRequester)}원
                        </span>
                      </div>
                    </div>
                    <div className="add-content">  {addLineBreaks(item.restChargeInfo.etc)}</div>
                  </label>
                </label>
              ),
            )
          )}
        </div>
      </div>

      <div className="line"></div>
      {/* 결제금액 부분 */}
      <div className="pay-agree-wrap">
        <div className="pay-info-wrap">
          <div className="pay-info">
            <span>총 추가요금 </span>
            <span className="price">
              {formatNumber(totalAddAmt + totalInsAmt)}원{' '}
            </span>
          </div>

          <div className="pay-ment">
            <p className="ment">
              *추가 금액은 현장 키오스크에서 결제 진행됩니다.
            </p>
          </div>
        </div>
        {/* 동의 체크 부분 */}
        <div className="agree-wrap">
          <Check
            label={
              '자차특약 및 기타상품 내용과 추가 금액을 모두 확인하였습니다.'
            }
            onChange={handleChange}
            active={!isChecked}
          ></Check>
        </div>
        <div className="temp-area"></div>
      </div>
      <Button
        onClick={nextStep}
        disabled={!isChecked}
        children={'다음'}
      ></Button>
    </>
  )
}

export default AddProduct
