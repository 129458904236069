import { ChangeEventHandler } from "react";
import "./scss/Swich.scss";

interface CheckProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value: boolean;
}

const Swich: React.FC<CheckProps> = ({ onChange, value }) => {
  return (
    <>
      <label className="nm-switch">
        <input type="checkbox" onChange={onChange} checked={value} />
        <span className={`switch ${value ? 'checked' : ''}`}></span>
      </label>
    </>
  );
};

export default Swich;
