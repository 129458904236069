// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.res-info-wrap {
  flex-direction: column;
  text-align: left;
  padding: 10px;
}
.res-info-wrap span {
  margin-bottom: 10px;
  white-space: nowrap;
}

.res-wrap {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.res-wrap > div {
  color: #9fa4a9;
  font-weight: 500;
  margin-bottom: 10px;
}

.res-wrap .span-container {
  display: flex;
}

.res-wrap .span-container .label {
  color: #9fa4a9;
  margin-right: 10px;
  min-width: 100px;
  text-align: left;
}

.res-wrap .span-container > span {
  color: #464c52;
  white-space: normal;
}

.pay-total-wrap {
  text-align: left;
  padding: 10px;
}

.pay-total-child {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pay-total-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 2.5;
  padding-left: 10px;
}

.pay-total {
  margin-right: 20px;
  color: #4667d9;
  font-weight: 600;
  font-size: 18px;
}

.pay-ment {
  font-size: 14px;
  padding: 16px;
  background: #e9ebed;
  border-radius: 8px;
  margin-top: 15px;
  text-align: justify;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/scss/resFinal.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,gBAAA;EACA,aAAA;AACF;AACE;EACE,mBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,cAAA;EACA,mBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,aAAA;AAAF;;AAGA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAAF;;AAGA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AAAF","sourcesContent":[".res-info-wrap {\n  flex-direction: column;\n  text-align: left;\n  padding: 10px;\n\n  span {\n    margin-bottom: 10px;\n    white-space: nowrap;\n  }\n}\n\n.res-wrap {\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n.res-wrap > div {\n  color: #9fa4a9;\n  font-weight: 500;\n  margin-bottom: 10px;\n}\n\n.res-wrap .span-container {\n  display: flex;\n}\n\n.res-wrap .span-container .label {\n  color: #9fa4a9;\n  margin-right: 10px;\n  min-width: 100px;\n  text-align: left;\n}\n\n.res-wrap .span-container > span {\n  color: #464c52;\n  white-space: normal;\n}\n\n.pay-total-wrap {\n  text-align: left;\n  padding: 10px;\n}\n\n.pay-total-child {\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.pay-total-title {\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 2.5;\n  padding-left: 10px;\n}\n\n.pay-total {\n  margin-right: 20px;\n  color: #4667d9;\n  font-weight: 600;\n  font-size: 18px;\n}\n\n.pay-ment {\n  font-size: 14px;\n  padding: 16px;\n  background: #e9ebed;\n  border-radius: 8px;\n  margin-top: 15px;\n  text-align: justify;\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
