import "./scss/Step.scss";
interface Props {
  phase?: string | "1";
}

const Step: React.FC<Props> = ({ phase = "1" }) => {
  return (
    <>
      <div className="progress-wrap">
        <img src={`/img/progress_${phase}.svg`} style={{ width: "40vh" }}></img>
      </div>
    </>
  );
};

export default Step;
